<template>
    <b-modal
        id="csvImportModal"
        class="modal-demo"
        title-tag="h4"
        size="md"
        centered
        @hidden="hideModal"
    >
        <template slot="modal-title">
            Sync data with Klaviyo
        </template>

        <div class="form-group">
            <label for="file">CSV File</label>
            <input
                id="file"
                ref="fileInput"
                type="file"
                class="form-control"
                :class="{ 'is-invalid': error }"
                accept=".csv"
                @input="error = ''"
            />
            <span class="invalid-feedback">
                {{ error }}
            </span>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    :disabled="inProcess"
                    @click="importData"
                >
                    {{ inProcess ? 'Working...' : 'Import' }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            error: '',
            inProcess: false
        };
    },
    methods: {
        ...mapActions({
            klaviyoSync: 'orders/klaviyoSync'
        }),

        hideModal() {
            this.$bvModal.hide('csvImportModal');
        },

        async importData() {
            this.error = '';
            this.inProcess = true;
            const file = this.$refs.fileInput.files[0];

            if (!file) {
                this.error = 'Please select file.';
                this.inProcess = false;

                return;
            }

            try {
                await this.klaviyoSync(file);

                this.$refs.fileInput.type = 'text';
                this.$refs.fileInput.type = 'file';

                this.$toaster('Data synced!');
            } catch (error) {
                console.error(error);

                if (
                    error.response.status === 422 &&
                    error.response.data.error
                ) {
                    this.error = error.response.data.error;
                } else {
                    this.$toasterError();
                }
            }

            this.inProcess = false;
        }
    }
};
</script>
