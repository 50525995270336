<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <div class="mb-2 d-flex justify-content-between">
                <button
                    v-b-modal.csvImportModal
                    class="btn btn-info waves-effect waves-light mr-2"
                    type="button"
                    :disabled="exporting"
                >
                    <i class="fe-repeat mr-1" />
                    Sync with Klaviyo
                </button>

                <generate-report />
            </div>

            <ul class="nav nav-pills navtab-bg nav-justified mt-3 mb-4">
                <li
                    v-for="(statusToDisplay, index) in statuses"
                    :key="index"
                    class="nav-item"
                >
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: status === statusToDisplay
                        }"
                        @click.prevent="changeStatus(statusToDisplay)"
                    >
                        {{ capitalize(statusToDisplay) }}
                    </a>
                </li>
            </ul>

            <awesome-table
                id="orders"
                :labels="labels"
                :items="orders"
                :options="{
                    addButton: false,
                    sort: true,
                    select: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false,
                    tableStyle: 'font-size: 12px;'
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @row-click="$router.push(`/orders/${$event.id}`)"
                @mouse-wheel-click="$openNewTab(`/orders/${$event.id}`)"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #selectButtons>
                    <button
                        class="btn btn-success waves-effect waves-light mr-1"
                        type="button"
                        :disabled="exporting"
                        @click="getSamePersonReport"
                    >
                        <span class="btn-label">
                            <i class="fe-users" />
                        </span>
                        Same Person Report
                    </button>

                    <button
                        class="btn btn-info waves-effect waves-light mr-1"
                        type="button"
                        :disabled="exporting"
                        @click="exportToCsv('default')"
                    >
                        <span class="btn-label">
                            <i class="fe-package" />
                        </span>
                        Products Report
                    </button>

                    <button
                        class="btn btn-info waves-effect waves-light mr-1"
                        type="button"
                        :disabled="exporting"
                        @click="exportToCsv('oto')"
                    >
                        <span class="btn-label">
                            <i class="fe-file-text" />
                        </span>
                        OTO Report
                    </button>
                </template>
                <template #[`items.order_number`]="{ item }">
                    {{ item.order_number || item.magento_order_number }}
                </template>
                <template #[`items.coupons`]="{ item }">
                    {{ getCodesNames(item.orderCode) }}
                </template>
                <template #[`items.gift_cards`]="{ item }">
                    {{ getGiftCardsCodes(item.orderGiftCard) }}
                </template>
                <template #[`items.total_net`]="{ item }">
                    {{ getTotalNet(item) | variantFixedPrice }}
                </template>
                <template #[`items.customer.orders_count`]="{ item }">
                    {{ item.customer.orders_count }}
                    <a
                        v-if="!customerId"
                        :href="`${appUrl}/customers/${item.customer_id}/orders`"
                        target="_blank"
                        class="action-icon"
                        @click.stop
                    >
                        <i class="fe-external-link" />
                    </a>
                </template>
                <template #[`items.OTO`]="{ item }">
                    <span
                        v-if="
                            item.orderProductColor.some(
                                product => product.from_one_time_offer
                            )
                        "
                        class="badge bg-soft-success text-success"
                    >
                        YES
                    </span>
                    <span
                        v-else-if="
                            !item.orderSecondaryProduct.some(
                                product => product.from_one_time_offer
                            ) && !item.oto_discount_accepted
                        "
                        class="badge bg-soft-warning text-warning"
                    >
                        NO
                    </span>
                    <span
                        v-if="
                            item.orderSecondaryProduct.some(
                                product => product.from_one_time_offer
                            )
                        "
                        class="badge bg-soft-success text-success"
                    >
                        CREAM
                    </span>
                    <span
                        v-if="item.oto_discount_accepted"
                        class="badge bg-soft-success text-success"
                    >
                        DISC
                    </span>
                </template>
                <template #[`items.reorder`]="{ item }">
                    <span
                        v-if="
                            item.orderProductColor.some(
                                product => product.from_reorder
                            )
                        "
                        class="badge bg-soft-success text-success"
                    >
                        YES
                    </span>
                    <span v-else class="badge bg-soft-warning text-warning">
                        NO
                    </span>
                </template>
                <template #[`items.number_of_accounts`]="{ item }">
                    {{ getAccountsQuantity(item.orderProductColor) }}
                </template>
                <template #[`items.orthotics_sku`]="{ item }">
                    {{ getSkuList(item.orderProductColor) }}
                </template>
                <template #[`items.creams_sku`]="{ item }">
                    {{ getSkuList(item.orderSecondaryProduct, true) }}
                </template>
                <template #[`items.total_orthotics`]="{ item }">
                    {{ getTotalQuantity(item.orderProductColor) }}
                </template>
                <template #[`items.total_creams`]="{ item }">
                    {{ getTotalQuantity(item.orderSecondaryProduct) }}
                </template>
                <template #[`items.total_ikf`]="{ item }">
                    {{ item.orderImpressionKits.length }}
                </template>
                <template #[`items.total_unique_items`]="{ item }">
                    {{
                        item.orderProductColor.length +
                            item.orderSecondaryProduct.length +
                            item.orderImpressionKits.length
                    }}
                </template>
                <template #[`items.total_items`]="{ item }">
                    {{
                        getTotalQuantity([
                            ...item.orderProductColor,
                            ...item.orderSecondaryProduct
                        ])
                    }}
                </template>
            </awesome-table>
        </div>

        <csv-import-modal />
    </layout>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';
import CsvImportModal from '@components/orders/CsvImportModal';
import GenerateReport from '@components/orders/GenerateReport';

export default {
    page: {
        title: 'Orders',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        CsvImportModal,
        GenerateReport
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'All Orders',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Orders',
                    active: true
                }
            ],
            labels: [
                'order_number',
                'email',
                {
                    value: 'shipping_country',
                    type: 'enum',
                    enumOptions: ['United States', 'Israel', 'Canada']
                },
                { value: 'coupons', sortable: false, filterable: false },
                { value: 'gift_cards', sortable: false, filterable: false },
                {
                    text: 'Net Total',
                    value: 'total_net',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Tax',
                    value: 'total_tax',
                    filter: 'variantFixedPrice',
                    type: 'number',
                    sum: true
                },
                {
                    text: 'Total',
                    value: 'total',
                    filter: 'variantFixedPrice',
                    type: 'number',
                    sum: true
                },
                'status',
                'logistics_status',
                'full_name',
                'phone_number',
                'shipping_address',
                'shipping_city',
                {
                    text: 'Customer Orders Number',
                    value: 'customer.orders_count',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'shipping_method',
                    filter: 'startCase'
                },
                {
                    value: 'shipping_cost',
                    text: 'Delivery Cost',
                    filter: 'variantFixedPrice'
                },
                {
                    value: 'OTO',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'reorder',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'number_of_accounts',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'orthotics_sku',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'creams_sku',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'total_orthotics',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'total_creams',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'total_ikf',
                    text: 'Total IKF',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'total_unique_items',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'total_items',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                }
            ],
            giftCardsLabels: [
                {
                    text: 'Gift Card Discount',
                    value: 'orderGiftCard[0].discount',
                    filter: 'variantFixedPrice',
                    sortable: false,
                    filterable: false
                }
            ],
            orders: [],
            customerId: null,
            codeId: null,
            giftCardId: null,
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            statuses: ['paid', 'pending', 'cancelled'],
            status: 'paid',
            selected: [],
            exporting: false,
            isLoading: false,
            appUrl: config.url
        };
    },

    async created() {
        if (this.$route.name === 'app-orders') {
            this.filters.status = {
                value: 'APP-PAID',
                type: 'eq'
            };

            this.title = 'App Orders';
        }

        this.customerId = this.$route.params.customerId;

        if (this.customerId) {
            try {
                const customer = await this.getCustomer(this.customerId);

                this.title = `Orders of ${customer.first_name} ${
                    customer.last_name
                }`;

                const customersHref = {
                    text: 'Customers',
                    to: '/customers'
                };

                this.items = [this.items[0], customersHref, this.items[1]];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.fetchOrders();

            return;
        }

        this.codeId = this.$route.params.codeId;

        if (this.codeId) {
            try {
                const code = await this.getCode(this.codeId);

                this.title = `Orders for ${code.name}`;

                const codeHref = {
                    text: 'Promo codes',
                    to: '/promo-codes'
                };

                this.items = [this.items[0], codeHref, this.items[1]];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }

        this.giftCardId = this.$route.params.giftCardId;

        if (this.giftCardId) {
            this.labels.splice(5, 0, ...this.giftCardsLabels);

            try {
                const giftCard = await this.getGiftCard(this.giftCardId);

                this.title = `Orders for ${giftCard.code}`;

                const giftCardHref = {
                    text: 'Gift Cards',
                    to: '/gift-cards'
                };

                this.items = [this.items[0], giftCardHref, this.items[1]];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }

        this.fetchOrders();
    },

    methods: {
        ...mapActions({
            getOrders: 'orders/index',
            getCode: 'codes/show',
            getGiftCard: 'giftCards/show',
            getCustomer: 'customers/show',
            csvExport: 'orders/csvExport',
            samePersonReportExport: 'orders/samePersonReportExport'
        }),

        async fetchOrders() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    status: this.status,
                    customerId: this.customerId,
                    codeId: this.codeId,
                    giftCardId: this.giftCardId,
                    filters: this.filters
                };

                const { rows, count } = await this.getOrders(options);

                if (
                    options.currentPage !== this.pagination.currentPage ||
                    options.status !== this.status
                ) {
                    return;
                }

                this.orders = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        changeStatus(status) {
            this.status = status;
            this.pagination = {
                ...this.pagination,
                currentPage: 1
            };

            this.fetchOrders();
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchOrders();
        },

        capitalize(val) {
            if (typeof val !== 'string') {
                return '';
            }

            return val.charAt(0).toUpperCase() + val.slice(1);
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        async exportToCsv(type) {
            this.exporting = true;

            try {
                const csvContent = await this.csvExport({
                    ids: this.selected,
                    type
                });

                this.prepareCsv('Orders', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        async getSamePersonReport() {
            this.exporting = true;

            try {
                const csvContent = await this.samePersonReportExport(
                    this.selected
                );

                this.prepareCsv('Same_person_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        prepareCsv(fileName, csvContent) {
            const fileURL = window.URL.createObjectURL(new Blob([csvContent]));
            const fileLink = document.createElement('a');

            const date = moment().format('YYYY-MM-DD_HH-mm-ss');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${fileName}_${date}.csv`);
            document.body.appendChild(fileLink);

            fileLink.click();
        },

        getCodesNames(orderCode) {
            if (!orderCode.length) {
                return '-';
            }

            const codes = orderCode.map(({ code }) => code.name).join(', ');

            return codes;
        },

        getGiftCardsCodes(orderGiftCard) {
            if (!orderGiftCard.length) {
                return '-';
            }

            const giftCards = orderGiftCard
                .map(({ giftCard }) => giftCard.code)
                .join(', ');

            return giftCards;
        },

        getTotalNet(order) {
            const totalNonTaxed = order.total - order.total_tax;

            if (totalNonTaxed < 0) {
                return 0;
            }

            return totalNonTaxed;
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchOrders();
        },

        getTotalQuantity(items) {
            return items.reduce(
                (counter, currentItem) => counter + currentItem.quantity,
                0
            );
        },

        getSkuList(items, secondaryProducts = false) {
            let skuList = [];

            if (secondaryProducts) {
                skuList = items.map(item => item.secondaryProduct.sku);
            } else {
                skuList = items.map(
                    item => item.productColor.product.sku || item.assigned_sku
                );
            }

            skuList = skuList.filter(sku => sku);

            if (!skuList.length) {
                return '-';
            }

            return [...new Set(skuList)]
                .sort((a, b) => a.localeCompare(b))
                .join(', ');
        },

        getAccountsQuantity(items) {
            if (!items.length) {
                return 1;
            }

            const accountsIndexes = [];

            for (const item of items) {
                if (item.account && ~item.account.index) {
                    accountsIndexes.push(item.account.index);
                }
            }

            if (!accountsIndexes.length) {
                return 1;
            }

            return [...new Set(accountsIndexes)].length;
        }
    }
};
</script>
