var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"mb-2 d-flex justify-content-between"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.csvImportModal",modifiers:{"csvImportModal":true}}],staticClass:"btn btn-info waves-effect waves-light mr-2",attrs:{"type":"button","disabled":_vm.exporting}},[_c('i',{staticClass:"fe-repeat mr-1"}),_vm._v("\n                Sync with Klaviyo\n            ")]),_c('generate-report')],1),_c('ul',{staticClass:"nav nav-pills navtab-bg nav-justified mt-3 mb-4"},_vm._l((_vm.statuses),function(statusToDisplay,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                        active: _vm.status === statusToDisplay
                    },attrs:{"href":"#","data-toggle":"tab","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(statusToDisplay)}}},[_vm._v("\n                    "+_vm._s(_vm.capitalize(statusToDisplay))+"\n                ")])])}),0),_c('awesome-table',{attrs:{"id":"orders","labels":_vm.labels,"items":_vm.orders,"options":{
                addButton: false,
                sort: true,
                select: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false,
                tableStyle: 'font-size: 12px;'
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"row-click":function($event){return _vm.$router.push(("/orders/" + ($event.id)))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/orders/" + ($event.id)))},"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"selectButtons",fn:function(){return [_c('button',{staticClass:"btn btn-success waves-effect waves-light mr-1",attrs:{"type":"button","disabled":_vm.exporting},on:{"click":_vm.getSamePersonReport}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fe-users"})]),_vm._v("\n                    Same Person Report\n                ")]),_c('button',{staticClass:"btn btn-info waves-effect waves-light mr-1",attrs:{"type":"button","disabled":_vm.exporting},on:{"click":function($event){return _vm.exportToCsv('default')}}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fe-package"})]),_vm._v("\n                    Products Report\n                ")]),_c('button',{staticClass:"btn btn-info waves-effect waves-light mr-1",attrs:{"type":"button","disabled":_vm.exporting},on:{"click":function($event){return _vm.exportToCsv('oto')}}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fe-file-text"})]),_vm._v("\n                    OTO Report\n                ")])]},proxy:true},{key:"items.order_number",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.order_number || item.magento_order_number)+"\n            ")]}},{key:"items.coupons",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getCodesNames(item.orderCode))+"\n            ")]}},{key:"items.gift_cards",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getGiftCardsCodes(item.orderGiftCard))+"\n            ")]}},{key:"items.total_net",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("variantFixedPrice")(_vm.getTotalNet(item)))+"\n            ")]}},{key:"items.customer.orders_count",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.customer.orders_count)+"\n                "),(!_vm.customerId)?_c('a',{staticClass:"action-icon",attrs:{"href":(_vm.appUrl + "/customers/" + (item.customer_id) + "/orders"),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fe-external-link"})]):_vm._e()]}},{key:"items.OTO",fn:function(ref){
            var item = ref.item;
return [(
                        item.orderProductColor.some(
                            function (product) { return product.from_one_time_offer; }
                        )
                    )?_c('span',{staticClass:"badge bg-soft-success text-success"},[_vm._v("\n                    YES\n                ")]):(
                        !item.orderSecondaryProduct.some(
                            function (product) { return product.from_one_time_offer; }
                        ) && !item.oto_discount_accepted
                    )?_c('span',{staticClass:"badge bg-soft-warning text-warning"},[_vm._v("\n                    NO\n                ")]):_vm._e(),(
                        item.orderSecondaryProduct.some(
                            function (product) { return product.from_one_time_offer; }
                        )
                    )?_c('span',{staticClass:"badge bg-soft-success text-success"},[_vm._v("\n                    CREAM\n                ")]):_vm._e(),(item.oto_discount_accepted)?_c('span',{staticClass:"badge bg-soft-success text-success"},[_vm._v("\n                    DISC\n                ")]):_vm._e()]}},{key:"items.reorder",fn:function(ref){
                    var item = ref.item;
return [(
                        item.orderProductColor.some(
                            function (product) { return product.from_reorder; }
                        )
                    )?_c('span',{staticClass:"badge bg-soft-success text-success"},[_vm._v("\n                    YES\n                ")]):_c('span',{staticClass:"badge bg-soft-warning text-warning"},[_vm._v("\n                    NO\n                ")])]}},{key:"items.number_of_accounts",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getAccountsQuantity(item.orderProductColor))+"\n            ")]}},{key:"items.orthotics_sku",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getSkuList(item.orderProductColor))+"\n            ")]}},{key:"items.creams_sku",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getSkuList(item.orderSecondaryProduct, true))+"\n            ")]}},{key:"items.total_orthotics",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getTotalQuantity(item.orderProductColor))+"\n            ")]}},{key:"items.total_creams",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getTotalQuantity(item.orderSecondaryProduct))+"\n            ")]}},{key:"items.total_ikf",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.orderImpressionKits.length)+"\n            ")]}},{key:"items.total_unique_items",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.orderProductColor.length +
                        item.orderSecondaryProduct.length +
                        item.orderImpressionKits.length)+"\n            ")]}},{key:"items.total_items",fn:function(ref){
                        var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getTotalQuantity(item.orderProductColor.concat( item.orderSecondaryProduct
                    )))+"\n            ")]}}],null,true)})],1),_c('csv-import-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }