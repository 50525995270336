<template>
    <div>
        <div class="input-group">
            <date-picker
                v-model="dates"
                range
                value-type="YYYY-MM-DD"
                :format="'DD.MM.YYYY'"
                :lang="{
                    formatLocale: {
                        firstDayOfWeek: 1
                    }
                }"
                placeholder="Select date period"
            />
            <span class="btn btn-blue input-group-append">
                <i class="mdi mdi-calendar-range" />
            </span>

            <b-dropdown
                variant="blue"
                class="ml-1"
                style="width: 160px;"
                :disabled="exporting"
            >
                <template slot="button-content">
                    {{ exporting ? 'Exporting...' : 'Generate Report' }}
                    <i class="mdi mdi-chevron-down ml-1" />
                </template>
                <b-dropdown-item href="#" @click="getDailyReport">
                    <i class="fe-download mr-1" />
                    Daily Report
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="getQuantityReport">
                    <i class="fe-download mr-1" />
                    Quantity Report
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="getProductsReport">
                    <i class="fe-download mr-1" />
                    Products Report
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="getProductsPriceReport">
                    <i class="fe-download mr-1" />
                    Products Price Report
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker
    },

    data() {
        return {
            dates: [],
            exporting: false
        };
    },

    methods: {
        ...mapActions({
            csvExport: 'orders/csvExport',
            dailyReportExport: 'orders/dailyReportExport',
            quantityReportExport: 'orders/quantityReportExport',
            productsPriceReportExport: 'orders/productsPriceReportExport'
        }),

        async getDailyReport() {
            if (!this.dates.length) {
                this.$toasterWarning('Please select date period!');

                return;
            }

            this.exporting = true;

            const [startDate, endDate] = this.dates;

            try {
                const csvContent = await this.dailyReportExport({
                    startDate,
                    endDate
                });

                this.prepareCsv('Daily_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        async getQuantityReport() {
            if (!this.dates.length) {
                this.$toasterWarning('Please select date period!');

                return;
            }

            this.exporting = true;

            const [startDate, endDate] = this.dates;

            try {
                const csvContent = await this.quantityReportExport({
                    startDate,
                    endDate
                });

                this.prepareCsv('Quantity_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        async getProductsPriceReport() {
            if (!this.dates.length) {
                this.$toasterWarning('Please select date period!');

                return;
            }

            this.exporting = true;

            const [startDate, endDate] = this.dates;

            try {
                const csvContent = await this.productsPriceReportExport({
                    startDate,
                    endDate
                });

                this.prepareCsv('Products_price_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        async getProductsReport() {
            if (!this.dates.length) {
                this.$toasterWarning('Please select date period!');

                return;
            }

            this.exporting = true;

            const [startDate, endDate] = this.dates;

            try {
                const csvContent = await this.csvExport({
                    type: 'default',
                    startDate,
                    endDate
                });

                this.prepareCsv('Products_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        prepareCsv(fileName, csvContent) {
            const fileURL = window.URL.createObjectURL(new Blob([csvContent]));
            const fileLink = document.createElement('a');

            const date = moment().format('YYYY-MM-DD_HH-mm-ss');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${fileName}_${date}.csv`);
            document.body.appendChild(fileLink);

            fileLink.click();
        }
    }
};
</script>
